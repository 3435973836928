.table {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 12px;
    background-color: white;
}

.header {
    display: flex;
    flex-direction: row;
    background-color: #fafafa;
    padding: 12px;
    padding-right: 0px;
}

.headerText {
    flex: 1;
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    font-family: var(--font-family-medium);
}

.headerColumnBorder {
    border-left: 1px solid rgba(0, 0, 0, 0.03);
}

.column {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 14px;
}

.columnText {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    font-family: var(--font-family);
}

.patientColumn {
    justify-content: flex-start;
    text-align: left;
    flex: 2;
}

.healthStatusColumn {
    justify-content: center;
}

.patientColumnHeaderText {
    text-align: start;
}

.list {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.row {
    display: flex;
    flex-direction: row;
    padding: 12px;
    padding-right: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

.cellLeftIcon {
    margin-right: 20px;
}

.cellRightIcon {
    margin-left: 10px;
}

.link {
    text-decoration: none;
    color: #1890ff;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
}

.footerNavigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.footerNavigationText {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    font-family: var(--font-family);
}

.footerNavigationTextActive {
    color: #1890ff;
}

.footerSummary {
    margin-right: 16px;
}

.footerNavigationElement {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background: none;
    padding: 0px;
    cursor: pointer;
    margin-right: 8px;
}

.footerNavigationElementActive {
    border-color: #1890ff;
    columns: #1890ff;
}

.footerNavigationIconBack {
    transform: rotate(180deg);
}

.footerNavigationIconInactive {
    opacity: 0.4;
}
