.container {
    display: flex;
    flex-direction: row;
    flex: 1;
    position: relative;
}

.moodScale {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    left: 32px;
    top: 22px;
    height: 111px;
}

.monthMoodScale {
    height: 91px;
}

.threeMonthsMoodScale {
    height: 87px;
}

.moodIcon {
    width: 16px;
    height: 16px;
}

.chart {
    display: flex;
    flex: 1;
    height: 155px;
}
