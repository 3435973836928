.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--color-background-light);
}

.attributes {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #afd8ff;
    padding: 5px 16px 5px 16px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #cfe8ff;
    padding: 10px 16px 0px 16px;
}

.headerTabs {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.headerTab {
    display: flex;
    margin-right: 5px;
    padding: 10px 12px 8px 12px;
    background-color: #ecf6ff;
    border-radius: 4px 4px 0px 0px;
    text-decoration: none;
}

.headerTabActive {
    background-color: #afd8ff;
}

.headerTabLabel {
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    font-family: var(--font-family-medium);
}

.headerControls {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.headerControl {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 16px;
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border: none;
    background: none;
    padding: 0px 4px;
    cursor: pointer;
}

.headerControlIcon {
    margin-right: 4px;
}

.button {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border: none;
    background: none;
    padding: 0px 4px;
    cursor: pointer;
}

.text {
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    font-family: var(--font-family-medium);
}

.logoContainer {
    display: flex;
    background-color: white;
    border-radius: 4px;
    padding: 4px 13px 4px 7px;
    margin-right: 17px;
}
