.container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.chart {
    height: 155px;
}

.withTopMargin {
    margin-top: 40px;
}
