.content {
    display: flex;
    flex-direction: column;
    background-color: white;
    flex: 1;
    font-family: var(--font-family);
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #afd8ff;
}

.mainContent {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.navigation {
    display: flex;
    flex-direction: column;
    width: 160px;
    padding-right: 15px;
    background-color: #afd8ff;
}

.navigationControls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    width: 50px;
    border-radius: 30px;
    background-color: #2f6393;
    margin-left: 16px;
}

.navigationControl {
    display: flex;
    border: none;
    background: none;
    padding: 0px;
    cursor: pointer;
}

.navigationControlIcon {
    height: 16px;
    width: 16px;
}

.navigationSection {
    display: flex;
    padding: 10px;
    background-color: #cfe8ff;
    border-radius: 0px 4px 4px 0px;
    margin-bottom: 4px;
}

.navigationSectionActive {
    background-color: white;
}

.navigationSectionText {
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    font-family: var(--font-family);
}

.details {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #eff5f9;
    padding-right: 25px;
    padding-bottom: 12px;
}

.title {
    display: flex;
    flex-direction: row;
    background-color: #afd8ff;
    padding: 14px;
    padding-left: 64px;
}

.titleHeaderText {
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    font-family: var(--font-family-medium);
}
