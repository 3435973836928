.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background-dark);
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.line {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-family: var(--font-family);
}

.input {
    margin-left: 15px;
}

.button {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
}
