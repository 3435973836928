.statusIndicator {
    height: 20px;
    width: 20px;
    border-radius: 10px;
}

.good {
    background-color: #00d6b2;
}

.normal {
    background-color: #edbf44;
}

.bad {
    background-color: #ff7474;
}
