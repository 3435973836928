body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--font-family);
}

:root {
    --color-basic-dark: #1e2226;
    --color-basic-white: #ffffff;
    --color-basic-interactive: #0783c1;
    --color-basic-interactive-active: #006ca1;
    --color-basic-decorative: #0783c133;
    --color-basic-danger: #ff3f3d;
    --color-basic-grey: #748c97;
    --color-basic-disabled: #748c974d;
    --color-basic-light: #748c9726;

    --color-ranges-low: #02c165;
    --color-ranges-medium: #ff7802;
    --color-ranges-high: #ff3f3d;
    --color-ranges-very-high: #c2160c;

    --color-background-low: #ccf0df;
    --color-background-medium: #ffe8cd;
    --color-background-high: #ffe1e0;
    --color-background-very-high: #ffd0cd;
    --color-background-dark: #eff5f9;
    --color-background-light: #f8f9fb;

    --color-overlay: #162c4b;

    --elevations-button-shadow: 0px 2px 0px rgba(19, 82, 114, 0.2);
    --elevations-content-shadow: 0px 0px 14px #d6dfe6;

    --font-family: 'Futura PT Book';
    --font-family-medium: 'Futura PT Medium';
}
