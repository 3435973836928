.container {
    display: flex;
    flex-direction: row;
    flex: 1;
    background: var(--color-background-light);
}

.overview {
    display: flex;
    flex-direction: column;
    width: 250px;
    background-color: #afd8ff;
}

.details {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.patientInfo {
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #afd8ff;
    padding: 16px;
}

.patientName {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 6px;
}

.patientInfoBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 8px 0 8px 0;
    background: var(--color-background-light);
    border-radius: 5px;
    width: 100%;
}

.patientInfoItem {
    padding: 8px;
}

.patientInfoTitle {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

.infoItemLabel {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: var(--color-basic-grey);
}

.infoItemValue {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
}

.charts {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px 16px;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 450;
    text-transform: uppercase;
    font-family: var(--font-family);
}

.period {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: var(--font-family);
    margin-left: 16px;
}

.select {
    font-size: 20px;
    line-height: 24px;
    font-weight: 450;
    font-family: var(--font-family);
}

.tabsContainer {
    display: flex;
    flex-direction: row;
}

.tabs {
    background-color: #ececec;
    border-radius: 20px;
    padding: 4px;
}

.tab {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    border: none;
    background: none;
    padding: 4px 16px;
    cursor: pointer;
}

.activeTab {
    background-color: white;
    border-radius: 20px;
}

.periodNavigation {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: #ececec;
    border-radius: 20px;
    padding: 4px;
    margin-left: 16px;
}

.periodNavigationControl {
    display: flex;
    border: none;
    background: none;
    padding: 0px;
    cursor: pointer;
}

.disabledPeriodNavigationControl {
    opacity: 0.4;
}

.periodNavigationIcon {
    width: 32px;
    height: 32px;
}

.mainChartContainer {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: white;
    padding: 24px 16px;
    margin-top: 24px;
    z-index: 1;
    transition: box-shadow 0.2s;
}

.mainChartPinned {
    position: sticky;
    top: -85px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
}

.mainChartHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.mainChartPinIcon {
    width: 24px;
    height: 24px;
}

.mainChartPinControl {
    display: flex;
    border: none;
    background: none;
    padding: 0px;
    cursor: pointer;
    transition: box-shadow 0.2s;
}

.mainChartPinControlActive {
    box-shadow: 0px 1px rgba(0, 0, 0, 0.5);
}

.detailsContainer {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: white;
    padding: 24px 16px;
    margin-top: 24px;
}

.controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
}

.paddedChart {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.chart {
    position: relative;
    display: flex;
    flex: 20;
    margin-top: 40px;
}

.padding {
    display: flex;
    flex: 1;
}

.detailsVisibilityToggleControl {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    background: none;
    padding: 0px;
    cursor: pointer;
}

.detailsVisibilityToggleIcon {
    margin-left: 8px;
    width: 24px;
    height: 24px;
    transition: transform 0.3s;
}

.detailsVisibilityToggleIconUnfold {
    transform: rotate(180deg);
}
